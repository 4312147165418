import { Injectable } from '@angular/core';
import { Session } from "../models/Session.model";

@Injectable({
  providedIn: 'root'
})
export class OrmService {

  private localStorageService;

  constructor() {
    this.localStorageService = localStorage;
  }

  setCurrentSession(session: Session): void {
    this.localStorageService.setItem('UserPROD', JSON.stringify(session));
  }

  loadSessionData(): Session {
    var sessionStr = this.localStorageService.getItem('UserPROD');
    return (sessionStr) ? JSON.parse(sessionStr) : null;
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('UserPROD');
  }

  isAuthenticated(): boolean {
    //console.log(this.localStorageService.getItem('User'));
    return (this.getCurrentToken() != null && this.getCurrentToken() != '') ? true : false;
  };

  getCurrentToken(): string {
    var session = this.loadSessionData();
    return (session && session.token) ? session.token : '';
  };

  getCurrenMenu() {
    var session = this.loadSessionData();
    return (session && session.main) ? session.main : [];
  }

  getCurrentUser(): any {
    var session = this.loadSessionData();
    return (session && session.profile) ? session.profile : JSON.parse('[]');
  };

  isUrlValid(route: string) {
    const Menu = this.getCurrenMenu();
    var band = false;

    for(let menu of Menu) {
      if(menu.hijos.length > 0) {
        for(let menu2 of menu.hijos) {
          if(menu2.route === `/${route}`) {
            band = true;
            break;
          }
        }
      }
      if(menu.route === `/${route}`) {
        band = true;
        break;
      }
    }
    return band;
  }

  logout(): void {
    this.removeCurrentSession();
  }
}
