import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { OrmService } from '../../services/orm.service';
//import { Crypto } from '@angular/cli';
import { SideBarService } from 'src/app/services/side-bar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  file: any;
  fileS: any;
  color: string = '#ffffff';

  constructor(
    private API: ApiService,
    private ORM: OrmService,
    private router: Router,
    private sidebar: SideBarService
    ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    //var hash = Crypto.MD5("Message")
    //console.log(hash)
  }
 
  setColor(event: any) {
    console.log(this.color);
    this.color = this.color.replace('#', '%23');
    console.log(this.color);
  }
  
  onChange(event: any) {
    this.file = event.target.files[0];
  }

  onChangeSplash(event: any) {
    this.fileS = event.target.files[0];
  }
  
  onUpload(): void {
    if(!this.file) {
      alert('Error, Archivo Home, no existe!');
      return;
    } 
    if (!(/\.(jpg|png|gif)$/i).test(this.file.name)) {
      alert('El archivo a adjuntar no es una imagen');
      return;
    }
    let formData: FormData = new FormData();
    formData.append('file', this.file, this.file.name);
    this.API.postHomeBanner(formData).subscribe((data: any) => {
      console.log(data);
	    if(data.result == 'ok') {
        alert(this.file.name+' upload and mobile_version.json update!');
	      this.file = '';
      }
    });
  }

  onUploadSplash(): void {
    if(!this.fileS) {
      alert('Error, Archivo Splash Dinamic; no existe!');
      return;
    }
    if (!(/\.(jpg|png|gif)$/i).test(this.fileS.name)) {
      alert('El archivo a adjuntar no es una imagen');
      return;
    }
    let formData: FormData = new FormData();
    formData.append('file', this.fileS, this.fileS.name);
    this.API.postSplashDynamic(formData, this.color).subscribe((data: any) => {
	    console.log(data);
	    if(data.result == 'ok') {
	      alert(this.fileS.name+' upload and splash_dynamic.json update!');
	      this.fileS = '';
	    }
    });
  }

  logout(): void  {
    this.ORM.logout();
    this.router.navigate(['/login']);
  }
}
