<div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-6 col-xl-6">
            <div class="bg-light rounded h-100 p-4">
                <h6 class="mb-4">Lista de Modulos</h6>
                <ul class="list-unstyled mb-0">
                    <li *ngFor="let menu of main">
                        <div class="form-check form-switch">
                            <label class="form-check-label" >{{menu.name}}</label>
                        </div>
                        <ul *ngIf="menu.hijos.length > 0">
                            <li *ngFor="let nivel1 of menu.hijos">
                                <div class="form-check form-switch">
                                    <label class="form-check-label">{{nivel1.name}}</label>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-sm-6 col-xl-6">
            <form [formGroup]="form" (ngSubmit)="validForm($event)">
                <div class="form-floating mb-3">
                    <select class="form-select" id="level" formControlName="level" [(ngModel)]="Module.level" (change)="chageLevel()" name="level">
                        <option value="-1">Seleccione</option>
                        <option *ngFor="let Level of Levels" value={{Level.value}}>{{Level.name}}</option>
                    </select>
                    <label for="parent_id">Nivel</label>
                </div>
                <div class="form-floating mb-3">
                    <select class="form-select" id="parent_id" formControlName="parent_id" [(ngModel)]="Module.parent_id" (change)="setLevel()" name="parent_id">
                        <option value="">Seleccione</option>
                        <option *ngFor="let parent of Parents" value={{parent.id}}>{{parent.name}}</option>
                    </select>
                    <label for="parent_id">Padre</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="name" formControlName="name" required="required"  [(ngModel)]="Module.name">
                  <label for="name">Nombre</label>
                  <div *ngIf="form.controls.name.errors && form.controls.name.touched">
                      <small style="color: red;">Campo Obligatorio</small>
                  </div>
                </div>
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="route" formControlName="route" required="required"  [(ngModel)]="Module.route">
                    <label for="route">Ruta</label>
                    <div *ngIf="form.controls.route.errors && form.controls.route.touched">
                        <small style="color: red;">Campo Obligatorio</small>
                    </div>
                </div>
                <div class="form-floating mb-3">
                    <textarea class="form-control" id="description" formControlName="description" [(ngModel)]="Module.description" ></textarea>
                    <label for="description">Descripcion</label>
                </div>
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="iconcss" formControlName="iconcss" required="iconcss"  [(ngModel)]="Module.iconcss">
                    <label for="iconcss">Icon</label>
                </div>
                <div class="form-floating">
                    <div class="text-sm-end">
                        <button type="button" class="btn btn-success" (click)="save()">Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>