import { Component, OnInit, Input } from '@angular/core';
import { OrmService } from 'src/app/services/orm.service';
import { SideBarService } from 'src/app/services/side-bar.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {

  @Input() user: string = '';
  @Input() perfil: string = '';
  isOpen: string = '';

  constructor(
    private ORM: OrmService,
    private sideBarService: SideBarService,
  ) { }

  menu: any[] = [];

  ngOnInit(): void {
    this.menu = this.ORM.getCurrenMenu();
    this.sideBarService.change.subscribe((isOpen: any) => {
      this.isOpen = isOpen;
    });
  }

}
