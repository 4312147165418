import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';

interface password {
  id_user: null | string,
  password: string,
}

interface Filtros {
  status: number | null,
}

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  constructor(
    private API: ApiService,
    private formBuilder: FormBuilder,
    private Helper: HelperService,
    private sidebar: SideBarService,
  ) { }

  password: password = {
    id_user: null,
    password: ''
  }
  Filtros: Filtros = this.emptyFilters();
  Usuarios: any[] = [];

  ngOnInit(): void {
    this.password.password = this.passwordtext();
    this._getUsers();
  }

  formPassword = this.formBuilder.group({
    password: ['', Validators.required],
    id_user: ['', Validators.required],
  });

  validForm(event: Event) {
    event.preventDefault();
    if(this.formPassword.valid) {
      this.agregar();
    } else {
      this.formPassword.markAllAsTouched();
    }
  }

  agregar(): void {
    this.API._put(`/sistema/usuario/${this.password.id_user}`, {password: this.password.password}).subscribe((data: any) => {
      if(data.result == 'ok') {
        this.password = {
          id_user: null,
          password: ''
        }
        this.Helper.show('Datos Actualizados', { classname: `bg-success text-light`, delay: 1500 });
      } else {
        if(data.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    });
  }

  _getUsers() {
    this.API._get(`sistema/usuarios`, this.Filtros).subscribe((res: any) => {
      console.log(res)
      if(res.result == 'ok') {
        this.Usuarios = res.data.data;
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
      this.sidebar.showLoading();
    });
  }

  passwordtext(): string {
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let string_length = 8;
    let randomstring = "";
    for (let i=0; i<string_length; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum+1)
    }
    return randomstring;
  }

  emptyFilters(): Filtros {
    return {
      status: 1
    };
  }

}
