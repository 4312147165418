import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { OrmService } from '../../services/orm.service';
import { Router } from "@angular/router";
import { AppComponent } from 'src/app/app.component';

interface User {
   username: string,
   password: string
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  //user: string;
  //pass: string;

   User: User = {
    username: '',
	  password: ''
   }
  isLoading: boolean = false;
  constructor(
    private API: ApiService,
    private ORM: OrmService,
    private APP: AppComponent,
    private router: Router,
  ) {
      //this.user = '';
      //this.pass = '';
    }

  ngOnInit(): void {
    if(this.ORM.isAuthenticated()) {
      this.router.navigate(['/home']);
    }
  }

  async login() {
    this.isLoading = true;
    this.API.postLogin(this.User).subscribe((data: any) => {
      this.isLoading = false;
      console.log(data);
      if(data.result == 'ok') {
        this.ORM.setCurrentSession(data.data);
        //this.APP.isLogin = true;
        this.APP.menu = data.data.main;
        this.router.navigate(['/home']);
      }
    }, (e: any) => {
      console.warn('postLogin -> ', e);
    });
  }

}
