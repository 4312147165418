import { Component, OnInit } from '@angular/core';
import { SideBarService } from 'src/app/services/side-bar.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  show: string = 'show';

  constructor(
    private sideBarService: SideBarService,
  ) { }

  ngOnInit(): void {
    this.sideBarService.changeLoading.subscribe((show: any) => {
      console.log('loading', show)
      this.show = show;
    });
  }

}
