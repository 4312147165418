<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
        <div class="row">
            <h6 class="mb-4">{{view.title}}</h6>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="nombre" [(ngModel)]="Filtros.nombre">
                    <label for="nombre">Nombre</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="apellidos" [(ngModel)]="Filtros.apellidos">
                    <label for="apellidos">Apellidos</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="mxuser" [(ngModel)]="Filtros.mxuser">
                    <label for="mxuser">MXUSER</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="correo" [(ngModel)]="Filtros.correo">
                    <label for="correo">Correo</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="departamento" [(ngModel)]="Filtros.departamento">
                    <label for="departamento">Departamento</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="perfil" [(ngModel)]="Filtros.perfil">
                    <label for="perfil">Perfil</label>
                </div>
            </div>
        </div>
        <button class="btn btn-primary" (click)="listar()" [disabled]="view.isLoading">Buscar</button>
        <button class="btn btn-warning" (click)="clear()" [disabled]="view.isLoading">Limpiar</button>
    </div>
</div>
<div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12">
            <div class="bg-light rounded h-100 p-4 table-responsive">
                <a routerLink="/user" class="btn btn-success">Agregar Usuario</a>
                <ng-template [ngIf]="view.isLoading" [ngIfElse]="apiResponse">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status" >
                          <span class="sr-only" id="loading"></span>
                        </div>
                      </div>
                </ng-template>
                <ng-template #apiResponse>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Apellidos</th>
                                <th scope="col">Mxuser</th>
                                <th scope="col">Correo</th>
                                <th scope="col">Departamento</th>
                                <th scope="col">Perfil</th>
                                <th scope="col">Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template [ngIf]="resultados.length > 0" [ngIfElse]="notData">
                                <tr *ngFor="let Result of resultados">
                                    <td><a href="/user/{{Result.id_user}}">{{Result.id_user}}</a></td>
                                    <td>{{Result.nombre}}</td>
                                    <td>{{Result.apellidos}}</td>
                                    <td>{{Result.mxuser}}</td>
                                    <td>{{Result.correo}}</td>
                                    <td>{{Result.departamento}}</td>
                                    <td>{{Result.perfil}}</td>
                                    <td *ngIf="Result.status == 0; else elseBlock">
                                        <button class="btn btn-success" (click)="activateUser(Result.id_user)">Activar</button>
                                    </td>
                                    <ng-template #elseBlock>
                                        <td><p class="text-primary">Activo</p></td>
                                    </ng-template>
                                </tr>
                            </ng-template>
                            <ng-template #notData>
                                <tr>
                                    <td colspan="8" scope="row" style="text-align: center;">Sin Resultados!!</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                    <ngb-pagination [collectionSize]="total" [pageSize]="12" [maxSize]="6" [rotate]="true" [ellipses]="false" [(page)]="Filtros.page" (pageChange)="pagination($event)"></ngb-pagination>
                </ng-template>
            </div>
        </div>
    </div>
</div>