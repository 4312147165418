import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { HelperService } from 'src/app/services/helper.service';

interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface Filtros {
  subscription_id: null | number,
  date_ini: string,
  date_fin: string,
  name: string,
  email: string,
  name_customer: string,
  page: number,
  page_size: number | null
}

@Component({
  selector: 'app-promised-dates',
  templateUrl: './promised-dates.component.html',
  styleUrls: ['./promised-dates.component.css']
})
export class PromisedDatesComponent implements OnInit {

  view: view = {
    title: 'Ordenes Prometidas',
    isLoading: true,
    _url: 'promises'
  }

  Filtros: Filtros = this.emptyFilters();
  resultados: any[] = [];
  total: number = 0;

  constructor(
    private API: ApiService,
    private sidebar: SideBarService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    this.listar();
    //this.API.pr_get('/subscription').subscribe((res: any) => console.log(res))
  }

  listar(): void {
    console.log(this.Filtros)
    this.view.isLoading = true;
    this.API.pr_get(this.view._url, this.Filtros).subscribe((res: any) => {
      this.view.isLoading = false;
      console.log(res)
      if(res.success) {
        this.resultados = res.data.data;
        this.total = res.data.total * 12;
      }
    });
  }

  clear(): void {
    this.Filtros = this.emptyFilters();
    this.listar();
  }

  emptyFilters(): Filtros {
    return {
      subscription_id: null,
      date_ini: '',
      date_fin: '',
      name: '',
      email: '',
      name_customer: '',
      page: 1,
      page_size: null
    };
  }

  pagination(event: any) {
    this.Filtros.page = event;
    this.listar();
  }

}
