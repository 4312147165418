import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { SideBarService } from 'src/app/services/side-bar.service';
import { HelperService } from 'src/app/services/helper.service';



interface user {
  id_user?: number | null,
  mxuser: string,
  nombre: string,
  apellidos: string,
  correo: string | null,
  departamento: string,
  perfil: string,
  token: null,
  status: number,
  password: string,
  id_profile: number
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit{
  
  user: user = this.emptyFilters();


  constructor(
    private API: ApiService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private sidebar: SideBarService,
    private Helper: HelperService,
  ) { }

  ngOnInit(): void {
    this._set();
    this.sidebar.showLoading();
  }

  emptyFilters(): user {
    return {
      id_user: null,
      mxuser: "",
      nombre: "",
      apellidos: "",
      correo: "",
      departamento: "",
      perfil: "",
      token: null,
      status: 1,
      password: "",
      id_profile: 0    };
  }

  formUser = this.formBuilder.group({
    mx_user: ['', Validators.required],
    nombre: ['', Validators.required],
    apellidos: ['', Validators.required],
    correo: [''],
    departamento: [''],
    perfil: ['', Validators.required],
    token: [''],
    status: [''],
    password: ['', Validators.required],
    id_profile: ['', Validators.required],
  });

  validForm(event: Event) {
    event.preventDefault();
    if(this.formUser.valid) {
      this.agregar();
    } else {
      this.formUser.markAllAsTouched();
    }
  }

  agregar(){
    if(this.user.id_user === null){
      this.API._post("sistema/user", this.user).subscribe((res: any) => {
        console.log(res)
        
        if(res.result == 'ok') {
          this.router.navigate(['/users']);;
          //this.user = res.data;
          /*this.resultados = res.data.data;
          this.total = res.data.total * 12;*/
        } else {
          if(res.e.name === 'TokenExpiredError') {
            this.Helper.logOut();
          } else {
            this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
          }
        }
      }, (e: any) => {
        console.warn('search Reduce -> ', e);
      });
    }else{
      this.API._put('/sistema/usuario/'+ this.user.id_user,this.user).subscribe((re: any) => {
        console.log(re);
        if(re.result == 'ok'){
          //this.user = re.data
          this.Helper.show('Datos Actualizados', { classname: `bg-success text-light`, delay: 1500 });
        }
      })
    }
    console.log(this.user); 
  }

  _get(){
    this.API._get('/sistema/usuario/'+ this.user.id_user,[]).subscribe((re: any) => {
      console.log(re);
      if(re.result == 'ok'){
        this.user = re.data
      }
    })
  }

  _set(){
    this.user.id_user = Number(this.route.snapshot.paramMap.get('id')) == 0 ? null : Number(this.route.snapshot.paramMap.get('id'));
    if(this.user.id_user != null){
      this._get();
    }

  }


}
