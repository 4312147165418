<div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12 col-xl-6">
            <form [formGroup]="formProfile" (ngSubmit)="validForm($event)">
                <div class="bg-light rounded h-100 p-4">
                    <h6 class="mb-4">Perfil</h6>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="name" required="required" [(ngModel)]="Perfil.name">
                        <label for="name">Nombre</label>
                        <div *ngIf="formProfile.controls.name.errors && formProfile.controls.name.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <div class="form-floating mb-3">
                        <textarea class="form-control" formControlName="description" [(ngModel)]="Perfil.description"></textarea>
                        <label for="descripcion">Descripcion</label>
                        <div *ngIf="formProfile.controls.description.errors && formProfile.controls.description.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <div class="form-floating">
                        <div class="text-sm-end">
                            <button type="submit" class="btn btn-success">Guardar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-sm-12 col-xl-6">
            <div class="bg-light rounded h-100 p-4">
                <h6 class="mb-4">Lista de Modulos</h6>
                <ul class="list-unstyled mb-0">
                    <li *ngFor="let menu of main">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" (change)="addProfile($event)" value="{{menu.id}}" [checked]="menu.asig != null ? true : false">
                            <label class="form-check-label" >{{menu.name}}</label>
                        </div>
                        <ul *ngIf="menu.hijos.length > 0">
                            <li *ngFor="let nivel1 of menu.hijos">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" (change)="addProfile($event)" value="{{nivel1.id}}" [checked]="nivel1.asig != null ? true : false">
                                    <label class="form-check-label">{{nivel1.name}}</label>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!--div class="container-fluid pt-4 px-4">
    <div class="col-sm-12 col-xl-6">
        <div class="bg-light rounded h-100 p-4">
            <h6 class="mb-4">Lista de Modulos</h6>
            <ul class="list-unstyled mb-0">
                <li *ngFor="let menu of main">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" (change)="addProfile($event)" value="{{menu.id}}" [checked]="menu.asig != null ? true : false">
                        <label class="form-check-label" >{{menu.name}}</label>
                    </div>
                    <ul *ngIf="menu.hijos.length > 0">
                        <li *ngFor="let nivel1 of menu.hijos">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" (change)="addProfile($event)" value="{{nivel1.id}}" [checked]="nivel1.asig != null ? true : false">
                                <label class="form-check-label">{{nivel1.name}}</label>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div-->