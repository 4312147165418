import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class SideBarService {

  isOpen: string = '';
  show: string = 'show';

  @Output() change: EventEmitter<string> = new EventEmitter();
  @Output() changeLoading: EventEmitter<string> = new EventEmitter();

  toggle() {
    this.isOpen = this.isOpen === '' ? 'open' : '';
    this.change.emit(this.isOpen);
  }

  showLoading() {
    this.show = '';
    this.changeLoading.emit(this.show);
  }
}
