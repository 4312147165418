import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SideBarService } from 'src/app/services/side-bar.service';

interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface Filtros {
  name: string,
  name_customer: string,
  email: string,
  page: number,
  page_size: number | null,
  order_subscription: number | null
}

@Component({
  selector: 'app-pr-logs',
  templateUrl: './pr-logs.component.html',
  styleUrls: ['./pr-logs.component.css']
})
export class PrLogsComponent implements OnInit {

  view: view = {
    title: 'Log Pedidos Recurrentes',
    isLoading: true,
    _url: 'v1/logs'
  }

  Filtros: Filtros = this.emptyFilters();
  resultados: any[] = [];
  total: number = 0;

  constructor(
    private API: ApiService,
    private sidebar: SideBarService,
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    this.listar();
  }

  listar(): void {
    this.view.isLoading = true;
    this.API.pr_get(this.view._url, this.Filtros).subscribe((res: any) => {
      console.log(res)
      this.view.isLoading = false;
      if(res.success) {
        this.resultados = res.data.data;
        this.total = res.data.total * 12;
      }
    });
    
  }

  clear(): void {
    this.Filtros = this.emptyFilters();
    this.listar();
  }

  emptyFilters(): Filtros {
    return {
      name: '',
      name_customer: '',
      email: '',
      page: 1,
      page_size: null,
      order_subscription: null
    };
  }

  pagination(event: any) {
    this.Filtros.page = event;
    this.listar();
  }

}
