  <div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12 col-xl-12">
            <form [formGroup]="form" (ngSubmit)="validForm($event)">
              <div class="bg-light rounded h-100 p-4">
                <h6 class="mb-4">Cambiar Ip</h6>
                <div class="form-floating mb-3">
                  <select class="form-control" formControlName="env" required="required" [(ngModel)]="IPes.env">
                    <option *ngFor="let env of envs" value="{{env.value}}">{{env.name}}</option>
                  </select>
                  <label for="env">Ambiente</label>
                  <div *ngIf="form.controls.env.errors && form.controls.env.touched">
                      <small style="color: red;">Campo Obligatorio</small>
                  </div>
              </div>

              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="x_api_key" formControlName="x_api_key" required="required" [(ngModel)]="IPes.x_api_key">
                <label for="x_api_key">Token</label>
                <div *ngIf="form.controls.x_api_key.errors && form.controls.x_api_key.touched">
                    <small style="color: red;">Campo Obligatorio</small>
                </div>
              </div>

                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="username" formControlName="username" required="required" [(ngModel)]="IPes.username">
                    <label for="username">Usuario</label>
                    <div *ngIf="form.controls.username.errors && form.controls.username.touched">
                        <small style="color: red;">Campo Obligatorio</small>
                    </div>
                </div>

                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="ip" formControlName="ip" required="required" [(ngModel)]="IPes.ip">
                  <label for="ip">Ip</label>
                  <div *ngIf="form.controls.ip.errors && form.controls.ip.touched">
                      <small style="color: red;">Campo Obligatorio</small>
                  </div>
                </div>

                <div class="form-floating">
                  <div class="text-sm-end">
                    <button type="submit" class="btn btn-success">Guardar</button>
                  </div>
                </div>

              </div>
            </form>
          </div>
      </div>
  </div>