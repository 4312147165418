import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Perfil } from 'src/app/models/sistema.perfil.model';
import { SideBarService } from 'src/app/services/side-bar.service';
import { HelperService } from 'src/app/services/helper.service';

interface FilMenu {
  id_profile?: number | null,
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  Perfil: Perfil = this._objEmpty();
  main: any[] = [];
  main_profile: number[] = [];

  FilMenu: FilMenu = this.emptyFilters();

  constructor(
    private API: ApiService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private sidebar: SideBarService,
    private Helper: HelperService,
  ) { }

  formProfile = this.formBuilder.group({
    name: ['', Validators.required],
    description: [''],
  });

  validForm(event: Event) {
    event.preventDefault();
    if(this.formProfile.valid) {
      this.agregar();
    } else {
      this.formProfile.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this._set();
  }

  _set() {
    this.Perfil.id = Number(this.route.snapshot.paramMap.get('id')) == 0 ? null : Number(this.route.snapshot.paramMap.get('id'));
    if(this.Perfil.id) {
      this._get();
    } else {
      this._getMain();
    }
  }

  _get() {
    this.API._get(`sistema/perfil/${this.Perfil.id}`, []).subscribe((res: any) => {
      console.log(res);
      if(res.result == 'ok') {
        this.Perfil = res.data;
        this._getMain();
      }
    });
  }

  _getMain() {
    this.FilMenu.id_profile = this.Perfil.id;
    console.log(this.FilMenu)
    this.API._get(`/sistema/menu`, this.FilMenu).subscribe((res: any) => {
      console.log(res);
      if(res.result == 'ok') {
        this.main = res.data;

        this.main.map((data: any) => {
          if(data.hijos.length > 0) {
            data.hijos.map((data2: any) => {
              if(data2.asig != null) {
                this.addProfile({
                  target: {
                    defaultValue: data2.id
                  },
                  currentTarget: {
                    checked: true
                  },
                });
              }
            });
          }
          if(data.asig != null) {
            this.addProfile({
              target: {
                defaultValue: data.id
              },
              currentTarget: {
                checked: true
              },
            });
          }
        });

        this.sidebar.showLoading();
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.sidebar.showLoading();
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    });
  }

  _objEmpty(): Perfil {
    return new Perfil({
      id: null,
      name: null,
      description: null,
      status: 1
    });
  }

  emptyFilters(): FilMenu {
    return {
      id_profile: null
    };
  }

  agregar() {
    if(this.Perfil.id === null) {
      this.API._post(`sistema/perfil`, this.Perfil).subscribe((res: any) => {
        console.log(res);
        if(res.result == 'ok') {
          this.Perfil.id = res.data.id;
          this.sendMenu();
        } else {
          if(res.e.name === 'TokenExpiredError') {
            this.Helper.logOut();
          } else {
            this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
          }
        }
      });
    } else {
      this.API._put(`sistema/perfil/${this.Perfil.id}`, this.Perfil).subscribe((res: any) => {
        console.log(res);
        if(res.result == 'ok') {
          this.sendMenu();
        } else {
          if(res.e.name === 'TokenExpiredError') {
            this.Helper.logOut();
          } else {
            this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
          }
        }
      });
    }
  }

  addProfile(event: any) {
    const value = parseInt(event.target.defaultValue);
    if(event.currentTarget.checked) {
      if(!this.main_profile.includes(value)) {
        this.main_profile.push(value)
      }
    } else {
      this.main_profile.splice(this.main_profile.lastIndexOf(value), 1);
    }
  }

  sendMenu() {
    if(this.main_profile.length > 0) {
      this.API._post(`sistema/perfil/menu/${this.Perfil.id}`, this.main_profile).subscribe((res: any) => {
        console.log(res)
        if(res.result == 'ok') {
          this.router.navigate(['/profiles']);;
        } else {
          if(res.e.name === 'TokenExpiredError') {
            this.Helper.logOut();
          } else {
            this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
          }
        }
      })
    } else {
      this.router.navigate(['/profiles']);
    }
  }

}
