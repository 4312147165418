import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-store-code',
  templateUrl: './store-code.component.html',
  styleUrls: ['./store-code.component.css']
})

export class StoreCodeComponent implements OnInit {
  cp: string = '';
  Store: any;
    
  constructor(
    private API: ApiService,
    private sidebar: SideBarService,
    private formBuilder: FormBuilder,
    private Helper: HelperService,
  ) { }

  form = this.formBuilder.group({
    cp: ['', [Validators.required, Validators.minLength(3)]],
  });

  validForm(event: Event) {
    event.preventDefault();
    if(this.form.valid) {
      this.getStoreByCp();
    } else {
      this.form.markAllAsTouched();
    }
  }
    
  ngOnInit(): void {
    this.sidebar.showLoading();
  }

  getStoreByCp(): any {
    if(this.cp.length < 4) {
      return false;
    }
    
    this.Store = {};
    this.API._get(`orders/store/${this.cp}`, {}).subscribe((data: any) => {
      console.log(data)
      if(data.result == 'ok') {
        this.Store = data.data;
      } else {
        if(data.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    }, (e: any) => {
      console.warn('getStoreByCp -> ', e);
    });
  }
}
