import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { HelperService } from 'src/app/services/helper.service';

interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface Filtros {
  nombre: string | null,
  apellidos: string | null,
  mxuser: string | null,
  correo: string | null,
  departamento: string | null,
  perfil: string | null,
  page: number,
  page_size: number | null
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  view: view = {
    title: 'Usuarios',
    isLoading: true,
    _url: 'sistema/usuarios'
  }
  Filtros: Filtros = this.emptyFilters();
  resultados: any[] = [];
  total: number = 0;

  constructor(
    private API: ApiService,
    private sidebar: SideBarService,
    private Helper: HelperService,
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    this.listar();
  }

  listar(): void {
    this.view.isLoading = true;
    this.API._get(this.view._url, this.Filtros).subscribe((res: any) => {
      this.view.isLoading = false;
      console.log(res)
      if(res.result == 'ok') {
        this.resultados = res.data.data;
        this.total = res.data.total * 12;
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    });
  }

  clear(): void {
    this.Filtros = this.emptyFilters();
    this.listar();
  }

  emptyFilters(): Filtros {
    return {
      nombre: null,
      apellidos: null,
      mxuser: null,
      correo: null,
      departamento: null,
      perfil: null,
      page: 1,
      page_size: null
    };
  }

  pagination(event: any) {
    this.Filtros.page = event;
    this.listar();
  }

  activateUser(id: number) {
    console.log(id)
    this.API._put(`/sistema/usuario/${id}`, {status: 1}).subscribe((res: any) => {
      console.log(res)
      if(res.result == 'ok') {
        this.listar();
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    });
  }

}
