import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { HelperService } from 'src/app/services/helper.service';

interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface Filtros {
  mail: string | null,
  page: number | null,
  page_size: number | null
}

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
  ) { }

  view: view = {
    title: 'Carritos',
    isLoading: true,
    _url: 'magento/shopping-cart'
  }
  Filtros: Filtros = this.emptyFilters();
  resultados: any[] = [];
  total: number = 0;

  ngOnInit(): void {
    this.listar();
    this.sidebar.showLoading();
  }

  listar(): void {
    if(this.Filtros.mail == null || this.Filtros.mail == '') {
      this.view.isLoading = false;
      return;
    }
    this.view.isLoading = true;
    this.API._get(this.view._url, this.Filtros).subscribe((res: any) => {
      this.view.isLoading = false;
      console.log(res)
      if(res.result == 'ok') {
        this.resultados = res.data;
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    });
  }

  clear(): void {
    this.Filtros = this.emptyFilters();
    this.listar();
  }

  emptyFilters(): Filtros {
    return {
      mail: null,
      page: 1,
      page_size: null
    };
  }

  pagination(event: any) {
    this.Filtros.page = event;
    this.listar();
  }

}
