export class Perfil {
    public id?: number | null;
    public name?: string | null;
    public description?: string | null;
    public status?: string | null;

    constructor(object: any) {
        this.id = (object.id) ? object.id : null;
        this.name = (object.name) ? object.name : null;
        this.description = (object.description) ? object.description : null;
        this.status = (object.status) ? object.status : null;
    }
} 