<div class="container-fluid pt-4 px-4">
  <div class="row g-4">
    <div class="col-sm-12">
      <form [formGroup]="form" (ngSubmit)="validForm($event)">
        <div class="bg-light rounded h-100 p-4">
          <h6 class="mb-4">Cambio de Tienda</h6>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" formControlName="increment_id" required="required" [(ngModel)]="update.increment_id">
            <label for="increment_id">Numero de Orden</label>
            <div *ngIf="form.controls.increment_id.errors && form.controls.increment_id.touched">
              <small style="color: red;">Campo Obligatorio</small>
            </div>
          </div>
          <div class="form-floating mb-3">
            <select class="form-control" formControlName="store_id" required="required"  [(ngModel)]="update.store_id">
              <option *ngFor="let Store of Stores" value={{Store.default_store_id}}>{{Store.name}}</option>
            </select>
            <label for="store_id">Seleccione Tienda</label>
            <div *ngIf="form.controls.store_id.errors && form.controls.store_id.touched">
              <small style="color: red;">Campo Obligatorio</small>
            </div>
          </div>
          <div class="form-floating">
            <div class="text-sm-end">
              <button type="submit" class="btn btn-success">Guardar</button>
            </div>
        </div>
        </div>
        <!--div class="form-group">
          <label for="increment_id">Numero de Orden</label>
          <input type="text" class="form-control" id="increment_id" name="increment_id" placeholder="0000000" [(ngModel)]="update.increment_id">
        </div>
        <div class="form-group">
          <label for="store_id">Seleccione Tienda</label>
          <select class="form-control" id="store_id" name="store_id" [(ngModel)]="update.store_id">
            <option *ngFor="let Store of Stores" value={{Store.default_store_id}}>{{Store.name}}</option>
          </select>
        </div-->
      </form>
    </div>
  </div>
</div>