import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './components/footer/footer.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { LoadingComponent } from './components/loading/loading.component';

@NgModule({
  declarations: [
    FooterComponent,
    NavBarComponent,
    NotificationComponent,
    SideBarComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ],
  exports: [
    FooterComponent,
    NavBarComponent,
    NotificationComponent,
    SideBarComponent,
    LoadingComponent
  ]
})
export class SharedModule { }