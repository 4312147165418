import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { AppService } from 'src/app/app.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FormControl,FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface Filtros {
  type: string | null,
  sku: string | null,
  id: string | null,
  description: string | null,
  page: number,
  page_size: number | null,
  tienda: string | null
}

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {

  public showButton : boolean = true;
  public showFind : boolean = false;

  view: view = {
    title: 'Promociones',
    isLoading: false,
    _url: `/oms/offers`
  }
  Filtros: Filtros = this.emptyFilters();
  resultados: any[] = [];
  stores: any[] = [];
  total: number = 0;
  type: any = '';
  inputSend: any = '';
  selectSize = [5, 10, 20, 50, 100]
  reorderable                   = true;

  dat           : any           = [];
  columns       : any           = {};
  filteredRows  : any           = []; // inicialmente son los mismos que rows
  skus          : any           = []; 
  storeData     : any           = [];

  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
    private appService:AppService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    //this.getStoreFilterPromo();
    this.stores  = this.appService.getStoreDataVtex();
    this.columns = [
      { name: 'ID LOGIX', prop: 'id_logix' },
      { name: 'OFERTA', prop: 'offer_name' },
    
     
      { name: 'TIENDA', prop: 'store_name' },
      //{ name: 'DESCRIPCION', prop: 'description' },
     
      { name: 'INICIO', prop: 'startdate' },
      { name: 'FIN', prop:'enddate'},
      { name: 'STATUS', prop: 'status' },
      { name: 'SKUS COMPRA', prop: 'offer_group_products' },
      { name: 'SKUS REGALO', prop: 'offer_reward_products' },
     
      // { name: 'OFFERID', prop: 'offer_id' },
    
    
      { name: 'TIPO', prop: 'type_offer' },
    /*
      { name: 'EXTERNAL ID', prop: 'external_id' },
      { name: 'SKU', prop: 'product_sku' },
      { name: 'NOMBRE', prop: 'name_sku' },
      { name: 'DISCOUNT PIEZA', prop: 'discount_step' },
      { name: 'ACCION', prop: 'simple_action' },
      { name: 'DISCOUNT AMOUNT', prop: 'discount_amount' },
      { name: 'PORCENTAJE', prop: 'discount_percentage' }*/
     
      
     
    ];
  }

  listar() {
    this.InputSerach();
    this.resultados = [];
    this.dat        = [];
    this.view.isLoading = true;
    this.showButton     = false;
    this.showFind       = false;
    this.API._get(this.view._url, this.Filtros).subscribe((res: any) => {
  
      this.view.isLoading = false;
      this.showButton     = true;
      if(res.result == 'ok') {
        if (  res.data != ""  ) {
          this.appService.getShowToastFormat("Información exitosa.","success");
          this.dat =  res.data ;
          this.filteredRows = this.dat;
          this.showFind = true;
        }else{
          this.showFind = false;
          //console.log("dat  " + JSON.stringify( res.data));
          this.appService.getShowModalInfoFormat("¡AVISO!","No hay información relacionada con los datos proporcionados.","info");
          //this.Helper.show('No existe informacion en base a la proporcionado '  , { classname: `bg-warning text-light`, delay: 1500 });
        }
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          //this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
          this.appService.getShowModalInfoFormat("ERROR RESPONSE","Error en la respuesta del servicio:  "+ res.e,"error");
        }
      }
    }, (e: any) => {
      this.appService.getShowModalInfoFormat("ERROR RESPONSE","Error en la llamada del servicio:  "+ e,"error");
      console.warn('search Discount -> ', e);
    });
  }


  formUpDiscount = this.formBuilder.group({
    store:[''],
    sku:['']
  });

  validForm(event: Event) {
    event.preventDefault();
    //if(this.formUpDiscount.valid) {
      
     // const startDate = new Date(this.formUpDiscount.value.startDate);
      //const endDate = new Date(this.formUpDiscount.value.endDate);

      /*if (startDate > endDate) {
        this.Helper.show('La fecha de inicio no puede ser posterior a la fecha de fin. ', { classname: `bg-danger text-light`, delay: 3500 });
      } else {*/
      
        this.listar();
      //}
    /*} else {
      this.formUpDiscount.markAllAsTouched();
    }*/
  }


  
  getStoreFilterPromo() {
    this.API._get(`/stores`, []).subscribe((res: any) => {
      console.log(res)

      if(res.result == 'ok') {
        this.stores = res.data;
        /*this.resultados = res.data.data;
        this.total = res.data.total * 12;*/
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    }, (e: any) => {
      console.warn('search Reduce -> ', e);
    });
  }

  updateFilter(event : any) {
    const val = event.target.value.toLowerCase();
  
    // filtrar los datos según el término de búsqueda
    const temp = this.dat.filter(function(d: any) {
      return JSON.stringify(d).toLowerCase().indexOf(val) !== -1 || !val;
    });
  
    // actualizar la variable que contiene los datos filtrados
    this.filteredRows = temp;
  }

  clear(): void {
    this.Filtros = this.emptyFilters();
    this.inputSend = '';
    this.listar();
  }

  emptyFilters(): Filtros {
    return {
      type:null,
      sku: null,
      id: null,
      description: null,
      page: 1,
      page_size: 100,
      tienda: null
    };
  }

  pagination(event: any) {
    this.Filtros.page = event;
    this.listar();
  }

  InputSerach() {
    this.Filtros.type = this.type;
    if(this.type == 'SKU') {
      this.Filtros.sku = this.inputSend;
      this.Filtros.id = null;
      this.Filtros.description = null
    } else if (this.type == 'Evento'){
      this.Filtros.description =this.inputSend
      this.Filtros.sku = null;
      this.Filtros.id = null;
    } else {
      this.Filtros.sku = null;
      this.Filtros.description = null;
      this.Filtros.id = this.inputSend;
    }
  }


}
