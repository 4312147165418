import { Component, OnInit, Input, HostListener } from '@angular/core';
import { OrmService } from 'src/app/services/orm.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from './services/side-bar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isOpen: string = '';
  side: any = {
    user: '',
    perfil: ''
  }
  nav: any = {
    user: '',
  }
  menu: any[] = [];

  constructor(
    private ORM: OrmService,
    private Helper: HelperService,
    private sideBarService: SideBarService
  ) {}

  ngOnInit(): void {
    this.menu = this.ORM.getCurrenMenu();
    this.setSide();
  }

  @HostListener('click')
  click() {
    this.isOpen = this.sideBarService.isOpen;
  }

  isAuthenticated(): boolean {
    return this.ORM.isAuthenticated();
  }

  setSide() {
    const data = this.ORM.getCurrentUser();
    this.side.perfil = data.perfil;
    this.side.user = data.mxuser;
    this.nav.user = data.nombre;
    console.log(data)
  }

  salir() {
    this.Helper.logOut();
  }
}
