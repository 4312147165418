import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { HelperService } from 'src/app/services/helper.service';

interface Filtros {
  name: string,
}

@Component({
  selector: 'app-pr-config',
  templateUrl: './pr-config.component.html',
  styleUrls: ['./pr-config.component.css']
})
export class PrConfigComponent implements OnInit {

  Filtros: Filtros = this.emptyFilters();
  Config: any[] = []; 

  constructor(
    private API: ApiService,
    private sidebar: SideBarService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    this.getConf();
  }

  getConf() {
    this.API.pr_get(`configurations`, this.Filtros).subscribe((data: any) => {
      console.log(data);
      this.Config = data.data;
    }, (e: any) => { console.error(e) })
  } 

  emptyFilters(): Filtros {
    return {
      name: ''
    };
  }

}
