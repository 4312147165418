import { Component, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FormBuilder, Validators } from '@angular/forms';

import { AppService } from 'src/app/app.service';
import * as XLSX from 'xlsx';

type AOA = any[][];

interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface DiscountData {
  data: any[],
  startDate: Date | null,
  endDate: Date | null
}


@Component({
  selector: 'app-alta-rebajados',
  templateUrl: './alta-rebajados.component.html',
  styleUrls: ['./alta-rebajados.component.css']
})
export class AltaRebajadosComponent implements OnInit{

    loadingIndicator = true;
    reorderable = true;
    options = {}
    dat:any = [];
    columns: any = {};
    filteredRows  : any = []; // inicialmente son los mismos que rows
    skus          : any = []; 

    //formUpDiscount: FormGroup | undefined;
   
    DiscountData: DiscountData = this.emptyFilters();

    view: view = {
      title: 'Rebajados',
      isLoading: false,
      _url: `discount`
    }

    resultados: any[] = [];
    stores: any[] = [];
    total: number = 0;
    selectSize = [5, 10, 20, 50, 100]

  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
    private appService:AppService,
    private formBuilder: FormBuilder
  ) { }


  ngOnInit(): void {
    this.sidebar.showLoading();

        this.columns = [
            { key: 'sku', title: "sku" },
            { key: 'rebaja', title: 'rebaja' },
        ]

  }

  formUpDiscount = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required]
  });
  

  validForm(event: Event) {
    event.preventDefault();
    if(this.formUpDiscount.valid) {
      
      const startDate = new Date(this.formUpDiscount.value.startDate);
      const endDate = new Date(this.formUpDiscount.value.endDate);

      if (startDate > endDate) {
        this.Helper.show('La fecha de inicio no puede ser posterior a la fecha de fin. ', { classname: `bg-danger text-light`, delay: 3500 });
      } else {
        this.agregarDiscount();
      }
    } else {
      this.formUpDiscount.markAllAsTouched();
    }
  }

  agregarDiscount() : void {

    this.resultados = [];
    this.total = 0;
    this.view.isLoading = true;
    this.API._post(this.view._url, this.DiscountData).subscribe((res: any) => {
      console.log( " JSON STR " + JSON.stringify(  res ))
      this.view.isLoading = false;
      if(res.result == 'ok') {
        this.resultados = res.data.data;
        //this.total = res.data.total * 12;
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    }, (e: any) => {
      console.warn('search Discount -> ', e);
    });

  }

  // EXCEL READ 
  data: AOA = [[1, 2], [3, 4]];
  
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';


  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      this.data =  this.data.slice(1);
      console.log(this.data);
      this.dat = this.data;
      const invalidRows = this.data.filter(row => {
        return row.some(cell => isNaN(cell) || cell === '');
      });
  
      this.showTable = true;
      this.DiscountData.data = this.data;
      if (invalidRows.length > 0) {
        console.log('Hay filas con valores no numéricos');
        this.Helper.show('Existen campos vacios o valores que nos de tipo numérico. ', { classname: `bg-danger text-light`, delay: 3500 });
      } else {
        console.log('Todas las filas tienen valores numéricos');
        this.mostrarBoton = true;
        //this.DiscountData.data = this.data;
      }
      
    };
    reader.readAsBinaryString(target.files[0]);
  }

  emptyFilters(): DiscountData {
    return {
      data: [],
      startDate: null,
      endDate: null
    };
  }
  
  getCellClass(row: any, column: any) {
    if (!row[column.prop] ) {
      //console.log("negative");
      
      row[column.prop] = "Error: la columna está vacía";
      return 'negative-cell';
    } else if (row[column.prop] > 0) {
      //console.log("positive");
     
      return 'cell';
    } else {
      //console.log("nature");
      return 'neutral-cell';
    }
  }

  isEmptyCol(row: any, column: any) : boolean {
    //if (!row[column.prop] ) {
      return !row[column.prop];
    //}
  }
  isColumnEmpty(columnName: number): boolean {
    return this.data.some(row => !row[columnName]);
  }

  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


  

  updateFilter(event : any) {
    const val = event.target.value.toLowerCase();
  
    // filtrar los datos según el término de búsqueda
    const temp = this.dat.filter(function(d: any) {
      return JSON.stringify(d).toLowerCase().indexOf(val) !== -1 || !val;
    });
  
    // actualizar la variable que contiene los datos filtrados
    this.filteredRows = temp;
  }

  public mostrarBoton : boolean = true;
  public showTable    : boolean = true;   

}
