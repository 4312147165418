<div class="sidebar pe-4 pb-3 {{isOpen}}">
    <nav class="navbar bg-light navbar-light">
        <a href="/home" class="navbar-brand mx-4 mb-3">
            <h3 class="text-primary">ECOMTOOLS</h3>
        </a>
        <div class="d-flex align-items-center ms-4 mb-4">
            <div class="position-relative">
                <img class="rounded-circle" src="../assets/img/defaultuser.png" alt="" style="width: 40px; height: 40px;">
                <div class="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
            </div>
            <div class="ms-3">
                <h6 class="mb-0">{{user}}</h6>
                <span>{{perfil}}</span>
            </div>
        </div>
        <div class="navbar-nav w-100">
            <div *ngFor="let Menu of menu">
                <ng-template [ngIf]="Menu.hijos.length > 0" [ngIfElse]="link">
                    <div class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i class="{{Menu.iconcss}} me-2"></i>{{Menu.name}}</a>
                        <div class="dropdown-menu bg-transparent border-0">
                            <a *ngFor="let hijos of Menu.hijos" href="{{hijos.route}}" class="dropdown-item">{{hijos.name}}</a>
                        </div>
                    </div>
                </ng-template>
                <ng-template #link>
                    <a href="{{Menu.route}}" class="nav-item nav-link">
                        <i class="{{Menu.iconcss}} me-2"></i>
                        {{Menu.name}}
                    </a>
                </ng-template>
            </div>
        </div>
    </nav>
</div>