<div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12 col-xl-12">
            <form [formGroup]="formPassword" (ngSubmit)="validForm($event)">
                <div class="bg-light rounded h-100 p-4">
                    <h6 class="mb-4">Contraseña</h6>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="newpassword" formControlName="newpassword" required="required" [(ngModel)]="password.newpassword">
                        <label for="newpassword">Nueva Contraseña</label>
                        <div *ngIf="formPassword.controls.newpassword.errors && formPassword.controls.newpassword.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
    
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="repitpassword" formControlName="repitpassword" required="required" [(ngModel)]="password.repitpassword">
                        <label for="repitpassword">Repite Contraseña</label>
                        <div *ngIf="formPassword.controls.repitpassword.errors && formPassword.controls.repitpassword.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
    
                    <div class="form-floating">
                        <div class="text-sm-end">
                            <button type="submit" class="btn btn-success">Guardar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>