<div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12 col-xl-12">
            <form [formGroup]="formPassword" (ngSubmit)="validForm($event)">
                <div class="bg-light rounded h-100 p-4">
                    <h6 class="mb-4">Cambio de Contraseña</h6>
                    <div class="form-floating mb-3">
                        <select class="form-select" id="id_user" formControlName="id_user" [(ngModel)]="password.id_user" name="id_user" >
                            <option value="-1">Seleccione</option>
                            <option *ngFor="let Usuario of Usuarios" value={{Usuario.id_user}}>{{Usuario.mxuser}}</option>
                        </select>
                        <label for="id_user">Usuario</label>
                        <div *ngIf="formPassword.controls.id_user.errors && formPassword.controls.id_user.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="password" formControlName="password" required="required" [(ngModel)]="password.password">
                        <label for="password">Nueva Contraseña</label>
                        <div *ngIf="formPassword.controls.password.errors && formPassword.controls.password.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
    
                    <div class="form-floating">
                        <div class="text-sm-end">
                            <button type="submit" class="btn btn-success">Guardar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>