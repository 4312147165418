<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
        <div class="row">
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <label for="name">Estatus: {{PR.status_subscription}}</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <label for="name">Store_code: {{PR.store_code}}</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <label for="name">Store_id: {{PR.store_id}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <label for="name">PR: {{PR.name}}</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <label for="name">Cliente: {{PR.name_customer}}</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <label for="name">{{PR.email}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <label for="name">Registro: {{PR.register | date: 'd/M/yy'}}</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <label for="name">Inicio: {{PR.start_date | date: 'd/M/yy, h:mm a'}}</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <label for="name">Expira: {{PR.expires | date: 'd/M/yy, h:mm a'}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-floating mb-3">
                    <label for="name">Comentarios: {{PR.comments}}</label>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
        <div class="row">
            <h5 class="mb-4">Fechas</h5>
            <div class="col-sm-12">
                <div class="bg-light rounded h-100 p-4 table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Fecha Prometida</th>
                                <th scope="col">PR Estatus</th>
                                <th scope="col">Orden M2</th>
                                <th scope="col">Intentos</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template [ngIf]="PR.dates.length > 0" [ngIfElse]="notDataDates">
                                <tr *ngFor="let Date of PR.dates">
                                    <td>{{Date.order_id}}</td>
                                    <td>{{Date.date_promise | date: 'd/M/yy'}}</td>
                                    <td>{{Date.status}}</td>
                                    <td>
                                        <div *ngIf="Date.response != '';">
                                            <a target="_blank" href="https://panel.heb.com.mx/admin/sales/order/view/order_id/{{Date.response}}/key/d129c42bda4bff4f4b8c748c4541b4664e384f705991725db8ea534c3df9d6f0/">
                                                {{Date.response}}
                                            </a>
                                        </div>
                                    </td>
                                    <td><a (click)="logs(content, Date.order_id)" href="javascript:void(0)">Ver ({{Date.intentos}})</a></td>
                                </tr>
                            </ng-template>
                            <ng-template #notDataDates>
                                <tr>
                                    <td colspan="5" scope="row" style="text-align: center;">Sin Resultados!!</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <h5 class="mb-4">Items</h5>
            <div class="col-sm-12">
                <div class="bg-light rounded h-100 p-4 table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Img</th>
                                <th scope="col">Sku</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Precio</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template [ngIf]="PR.details.length > 0" [ngIfElse]="notDataItems">
                                <tr *ngFor="let Item of PR.details">
                                    <td><img src="{{Item.url}}" width="50px"></td>
                                    <td>{{Item.sku}}</td>
                                    <td>{{Item.name}}</td>
                                    <td>{{Item.cant}}</td>
                                    <td>{{Item.price | currency}}</td>
                                    <td>{{Item.total | currency}}</td>
                                </tr>
                                <tr>
                                    <th colspan="4"></th>
                                    <th>Total</th>
                                    <th>{{PR.total | currency}}</th>
                                </tr>
                            </ng-template>
                            <ng-template #notDataItems>
                                <tr>
                                    <td colspan="6" scope="row" style="text-align: center;">Sin Resultados!!</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header-lg">
      <h4 class="modal-title">Logs</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body-lg">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">date_send</th>
                    <th scope="col">request</th>
                    <th scope="col">response</th>
                </tr>
            </thead>
            <tbody>
                <ng-template [ngIf]="Logs.length > 0" [ngIfElse]="notDataLogs">
                    <tr *ngFor="let Log of Logs">
                        <td>{{Log.date_send | date: 'd/M/yy, h:mm a'}}</td>
                        <td>{{Log.request}}</td>
                        <td>{{Log.response}}</td>
                    </tr>
                </ng-template>
                <ng-template #notDataLogs>
                    <tr>
                        <td colspan="3" scope="row" style="text-align: center;">Sin Resultados!!</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>