import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service'

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor(private helperService: HelperService) { }

  allToast: any[] = [];
  
  ngOnInit() {
    this.allToast = this.helperService.toasts;
  }

  remove(data: any) {
    this.helperService.remove(data);
  }

}
