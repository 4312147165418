<div class="container-fluid pt-4 px-4">
  <div class="row g-4">
    <div class="col-sm-12">
    <form [formGroup]="form" (ngSubmit)="validForm($event)">
      <div class="bg-light rounded h-100 p-4">
        <h6 class="mb-4">Obtener Tienda</h6>
        <div class="form-floating mb-3">
          <input type="text" class="form-control" formControlName="cp" required="required" placeholder="00000" [(ngModel)]="cp" maxlength="5">
          <label for="increment_id">Codigo Postal</label>
          <div *ngIf="form.controls.cp.errors && form.controls.cp.touched">
            <small style="color: red;">Campo Obligatorio</small>
          </div>
        </div>

        <div *ngIf="Store">
          <ul>
            <li>{{Store.Codigo}} - {{Store.name}}</li>
          </ul>
        </div>

        <div class="form-floating">
          <div class="text-sm-end">
            <button type="submit" class="btn btn-primary">Consultar</button>
          </div>
        </div>
      </div>
        <!--div class="form-group">
            <label for="increment_id">Codigo Postal</label>
            <input type="number" class="form-control" id="cp" name="cp" placeholder="00000" [(ngModel)]="cp" maxlength="5" >
          </div>
          <div *ngIf="Store">
            <ul>
              <li>{{Store.Codigo}} - {{Store.name}}</li>
            </ul>
          </div>
          <div class="form-floating">
            <div class="text-sm-end">
                <button type="button" class="btn btn-success" (click)="getStoreByCp()">Buscar</button>
            </div>
        </div-->
      </form>
    </div>
  </div>
</div>
