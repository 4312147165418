<div class="container-xxl position-relative bg-white d-flex p-0">
  <!-- Sign In Start -->
  <div class="container-fluid">
      <div class="row h-100 align-items-center justify-content-center" style="min-height: 100vh;">
          <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
              <div class="bg-light rounded p-4 p-sm-5 my-4 mx-3">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                      <a class="">
                          <h3 class="text-primary">ECOMTOOLS</h3>
                      </a>
                  </div>
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" [(ngModel)]="User.username" name="username" id="username">
                      <label for="floatingInput">Usuario</label>
                  </div>
                  <div class="form-floating mb-4">
                    <input type="password" class="form-control" [(ngModel)]="User.password" name="pass" id="pass" >
                      <label for="floatingPassword">Contraseña</label>
                  </div>
                  <ng-template [ngIf]="isLoading" [ngIfElse]="apiResponse">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status" >
                          <span class="sr-only" id="loading"></span>
                        </div>
                      </div>
                </ng-template>
                <ng-template #apiResponse>
                    <button type="submit" class="btn btn-primary py-3 w-100 mb-4" (click)="login()">Ingresar</button>
                </ng-template>
              </div>
          </div>
      </div>
  </div>
  <!-- Sign In End -->
</div>
<!--div class="wrapper ">
    <div class="main-panel">
        <div class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header card-header-primary">
                      <h4 class="card-title">Inicio de Sesi&oacute;n</h4>
                      <p class="card-category">Completa el formulario</p>
                    </div>
                    <div class="card-body">
                      <form name="login">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="bmd-label-floating">Usuario (*)</label>
                              <input type="text" class="form-control" [(ngModel)]="User.username" name="username" id="username">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="bmd-label-floating">Contraseña (*)</label>
                              <input type="password" class="form-control" [(ngModel)]="User.password" name="pass" id="pass" >
                            </div>
                          </div>
                        </div>
                        <button type="button" class="btn btn-primary pull-right" (click)="login()">Iniciar</button>
                        <div class="clearfix"></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</div-->
