import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';

interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface Filtros {
  name: string | null,
  page: number,
  page_size: number | null
}

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class ProfilesComponent implements OnInit {

  view: view = {
    title: 'Perfiles',
    isLoading: true,
    _url: 'sistema/perfiles'
  }
  Filtros: Filtros = this.emptyFilters();
  resultados: any[] = [];
  total: number = 0;

  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    this.listar();
  }

  listar(): void {
    this.view.isLoading = true;
    this.API._get(this.view._url, this.Filtros).subscribe((res: any) => {
      this.view.isLoading = false;
      console.log(res)
      if(res.result == 'ok') {
        this.resultados = res.data.data;
        this.total = res.data.total * 12;
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    });
  }

  clear(): void {
    this.Filtros = this.emptyFilters();
    this.listar();
  }

  emptyFilters(): Filtros {
    return {
      name: null,
      page: 1,
      page_size: null
    };
  }

  pagination(event: any) {
    this.Filtros.page = event;
    this.listar();
  }

}
