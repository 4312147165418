<ng-template [ngIf]="isAuthenticated()" [ngIfElse]="logout">
    <div class="container-xxl position-relative bg-white d-flex p-0">

        <app-loading></app-loading>

        <app-side-bar [user]="side.user" [perfil]="side.perfil"></app-side-bar>

        <div class="content {{isOpen}}">

            <app-nav-bar [user]="nav.user"></app-nav-bar>
            
            <router-outlet></router-outlet>

        </div>
    </div>
    
    <app-notification aria-live="polite" aria-atomic="true"></app-notification>
</ng-template>
<ng-template #logout>
    <router-outlet></router-outlet>
</ng-template>
<ng-template #logout>
    <router-outlet></router-outlet>
</ng-template>