import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from './shared/shared.module';

import { GuardianService } from './services/guardian.service';
import { SysguardianService } from './services/sysguardian.service';
import { SideBarService } from './services/side-bar.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ReduceComponent } from './pages/magento/reduce/reduce.component';
import { OrderComponent } from './pages/magento/order/order.component';
import { AwsIpComponent } from './pages/aws-ip/aws-ip.component';
import { StoreCodeComponent } from './pages/magento/store-code/store-code.component';
import { RecurringOrdersComponent } from './pages/recurrentes/recurring-orders/recurring-orders.component';
import { PromisedDatesComponent } from './pages/recurrentes/promised-dates/promised-dates.component';
import { PrDetailComponent } from './pages/recurrentes/pr-detail/pr-detail.component';
import { PrConfigComponent } from './pages/recurrentes/pr-config/pr-config.component';
import { PrLogsComponent } from './pages/recurrentes/pr-logs/pr-logs.component';
import { ProfilesComponent } from './pages/sistema/profiles/profiles.component';
import { UsersComponent } from './pages/sistema/users/users.component';
import { ProfileComponent } from './pages/sistema/profile/profile.component';
import { UpdatePasswordComponent } from './pages/sistema/update-password/update-password.component';
import { PromotionsComponent } from './pages/magento/promotions/promotions.component';
import { ModulesComponent } from './pages/sistema/modules/modules.component';
import { ShoppingCartComponent } from './pages/magento/shopping-cart/shopping-cart.component';
import { MyprofileComponent } from './pages/myprofile/myprofile.component';
import { LastOrdersComponent } from './pages/magento/last-orders/last-orders.component';
import { UserComponent } from './pages/sistema/user/user.component';
import { AltaRebajadosComponent } from './pages/magento/alta-rebajados/alta-rebajados.component';
import { RushOrdersComponent } from './pages/magento/rush-orders/rush-orders.component';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AppService } from 'src/app/app.service';
import { ConciliacionComponent } from './pages/conciliacion/conciliacion.component';
import { PushInyeccionComponent } from './pages/events/push-inyeccion/push-inyeccion.component';
import { PushRordersComponent } from './pages/events/push-rorders/push-rorders.component';
import { DataMoventsComponent } from './pages/events/data-movents/data-movents.component';
@NgModule({
  declarations: [
    AppComponent,
    AwsIpComponent,
    HomeComponent,
    LoginComponent,
    ReduceComponent,
    OrderComponent,
    StoreCodeComponent,
    PromisedDatesComponent,
    RecurringOrdersComponent,
    PromisedDatesComponent,
    PrDetailComponent,
    PrConfigComponent,
    PrLogsComponent,
    ProfilesComponent,
    UsersComponent,
    ProfileComponent,
    UpdatePasswordComponent,
    PromotionsComponent,
    ModulesComponent,
    ShoppingCartComponent,
    MyprofileComponent,
    LastOrdersComponent,
    UserComponent,
    AltaRebajadosComponent,
    RushOrdersComponent,
    ConciliacionComponent,
    PushInyeccionComponent,
    PushRordersComponent,
    DataMoventsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    NgxDatatableModule
  ],
  providers: [
    GuardianService,
    SysguardianService,
    SideBarService,
    AppService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
