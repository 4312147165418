import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';

interface IP {
  env?: string,
  x_api_key: string,
  username: string | null,
  ip: string | null
}

@Component({
  selector: 'app-aws-ip',
  templateUrl: './aws-ip.component.html',
  styleUrls: ['./aws-ip.component.css']
})
export class AwsIpComponent implements OnInit {

  constructor(
    private API: ApiService,
    private sidebar: SideBarService,
    private formBuilder: FormBuilder,
    public helperService: HelperService,
  ) { }

  IPes: IP = this.emptyIp();

  envs: any[] = [
    {name: 'ssh - Staging', value: 'sshstaging'},
    {name: 'ssh - Production', value: 'sshprod'},
    {name: 'BD - Staging', value: 'bdstaging'},
  ];

  form = this.formBuilder.group({
    env: ['', Validators.required],
    x_api_key: ['', Validators.required],
    username: ['', Validators.required],
    ip: ['', Validators.required],
  });

  validForm(event: Event) {
    event.preventDefault();
    if(this.form.valid) {
      this.updateIp();
    } else {
      this.form.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.getMyIp();
    this.sidebar.showLoading();
  }

  getMyIp() {
    this.API.getMyIp().subscribe((data: any) => {
      console.log(data);
      if(data.ip !== undefined) {
        this.IPes.ip = data.ip
      }
    }, (e: any) => {
      console.warn('getStores -> ', e);
    });
  }

  updateIp(): void {
    const body = {
      username: this.IPes.username,
      ip: this.IPes.ip
    }
    this.API.ipServiceBack(this.IPes.x_api_key, body, {env: this.IPes.env}).subscribe((data: any) => {
      console.log(data);
      this.helperService.show(`${data.data}`, { classname: `bg-success text-light`, delay: 1500 });
    }, (e: any) => {
      console.warn('getStores -> ', e);
    });
  }

  emptyIp(): IP {
    return {
      env: '',
      x_api_key: '',
      username: '',
      ip: ''
    };
  }

}
