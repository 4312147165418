import { Injectable } from '@angular/core';
import { 
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot 
} from "@angular/router";
import { OrmService } from './orm.service';

@Injectable({
  providedIn: 'root'
})
export class SysguardianService implements CanActivate {

  constructor(
    private router: Router,
    private ORM: OrmService
  ) {}

  IsLogin: any;

  canActivate(route: ActivatedRouteSnapshot, status: RouterStateSnapshot) {
    console.log(route.url[0].path)
    if (this.ORM.isUrlValid(route.url[0].path)) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page
    this.router.navigate(['/home']);
    return false;
  }
}
