import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SideBarService } from 'src/app/services/side-bar.service';

//Interfaces locales
interface Module {
  id?: null | number,
  name: string,
  parent_id?: null | number,
  description: string,
  route: string,
  iconcss: string,
  level: number,
  active: number,
  type: string,
  orden: number
}

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css']
})
export class ModulesComponent implements OnInit {

  //Constructor
  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private formBuilder: FormBuilder,
    private sidebar: SideBarService,
  ) { }

  //Inicio de variables
  Levels: any[] = [
    {
      value: 0,
      name: 'Padre'
    },
    {
      value: 1,
      name: 'Hijo'
    }
  ];
  Module: Module = this._objEmpty();
  Parents: any[] = [];
  main: any[] = [];

  //Inicio de Formulario
  form = this.formBuilder.group({
    name: ['', Validators.required],
    parent_id: [''],
    description: [''],
    route: ['', Validators.required],
    iconcss: [''],
    level: [''],
    active: [''],
    orden: ['']
  });

  //Funciones
  ngOnInit(): void {
    this.setDataInputs();
  }

  //Funciones Api
  getParents(): void {
    this.API._get(`sistema/main`, {}).subscribe((res: any) => {
      console.log(res)
      if(res.result == 'ok') {
        this.Parents = res.data;
        this.form.controls['parent_id'].enable();
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    });
  }

  save(): void {
    this.API._post(`sistema/main`, this.Module).subscribe((res: any) => {
      console.log(res)
        if(res.result == 'ok') {
          this.Module = this._objEmpty();

          this._getMain();
        } else {
          if(res.e.name === 'TokenExpiredError') {
            this.Helper.logOut();
          } else {
            this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
          }
        }
    });
  }

  _getMain(): void {
    this.API._get(`/sistema/menu`, {}).subscribe((res: any) => {
      console.log(res);
      if(res.result == 'ok') {
        this.main = res.data;
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
      this.sidebar.showLoading();
    });
  }

  //Funciones Formulario
  validForm(event: Event) {
    event.preventDefault();
    if(this.form.valid) {
      this.save();
    } else {
      this.form.markAllAsTouched();
    }
  }

  setDataInputs(): void {
    this.form.controls['iconcss'].disable();
    this.form.controls['parent_id'].disable();
    this._getMain();
    //this.chageLevel();
  }

  chageLevel(): void {
    console.log(this.Module.level)
    if(this.Module.level == 0) {
      this.Module.parent_id = null;
      this.form.controls['parent_id'].disable();
      this.form.controls['iconcss'].enable();
      this.Parents.length > 0 ? [] : this.Parents.length;
    } else {
      this.form.controls['iconcss'].disable();
      this.getParents();
    }
  }

  setLevel(): void {
    this.Parents.find((obj: any) => {
      if(obj.id == this.Module.parent_id) {
        this.Module.orden = obj.top + 1;
      }
    });
  }

  _objEmpty(): Module {
    return {
      id: null,
      name: '',
      parent_id: null,
      description: '',
      route: '',
      iconcss: '',
      level: -1,
      active: 1,
      type: 'web',
      orden: 0
    }
  }

}
