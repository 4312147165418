import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { FormControl,FormBuilder, Validators } from '@angular/forms';

interface view {
  title: string,
  isLoading: boolean,
  isLoadingInyection: boolean
}

interface itemsModel {
  orderId: null,
  orderTotal: any
}

@Component({
  selector: 'app-data-movents',
  templateUrl: './data-movents.component.html',
  styleUrls: ['./data-movents.component.css']
})
export class DataMoventsComponent implements OnInit {

  itemsData : any[] = [];
  globalData : any[] = [];
  itemDataRes : any[] = [];
  itemsModel : itemsModel = {
    orderId: null,
    orderTotal: 0
  }
  reorderable = true;
  view: view = {
    title: 'Data Movents',
    isLoading: false,
    isLoadingInyection: false
  }
  showButtonSearch : boolean = true;


  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
  }

  formUpDiscount = this.formBuilder.group({orderId:['']});

  async getOrderData() {
    this.showButtonSearch = false;
    this.view.isLoading = true;
    if (this.formUpDiscount.value.orderId) {
      var data = {orderIdVtex: this.formUpDiscount.value.orderId};
      let dataOrder = JSON.parse(JSON.stringify(data));
      this.API.eventOrderMovents(dataOrder).subscribe((data: any) => {
        this.showButtonSearch = true;
        this.view.isLoading = false;  
        if (typeof data[0] != "undefined") {
          //this.itemsData = Object.values(data);
          this.globalData = Object.values(data);
          console.log(this.globalData);
          this.globalData.forEach((currentValue: any, index:any) => {
            this.itemsData.push({jsonData:JSON.stringify(currentValue.json),
                                create_date:currentValue.create_date,
                                modified_date:currentValue.modified_date,
                                ordr_version_id:currentValue.ordr_version_id,
                                from_event:currentValue.from_event,
                                requested_event:currentValue.requested_event,
                                status:currentValue.status,
                                order_total:currentValue.order_total
                            });
          });
          this.itemDataRes = this.itemsData;
          this.itemsModel.orderTotal = data[0].json.body.collections[0].header.customer.order_total;
        } else {
          this.itemsData = [];
          this.itemDataRes = [];
          this.globalData = [];
          //console.log(this.globalData);
          this.itemsModel.orderTotal = 0;
          this.Helper.show('No se encontraron datos con esa orden', { classname: `bg-danger text-light`, delay: 3500 });
        }
      }, (e: any) => {
        this.showButtonSearch = true;
        this.view.isLoading = false;
        console.log('DataMovents -> ', e);
      });
    } else {
      this.showButtonSearch = true;
      this.view.isLoading = false;
      this.Helper.show('Debe colocar una número de orden', { classname: `bg-danger text-light`, delay: 3500, autohide: true });
    }
  }

  updateFilter(event : any): void {
    let val = event.target.value.toLowerCase();
    if (event.target.value) {
      let temp = this.itemsData.filter(function(d: any) {
        return JSON.stringify(d).toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.itemsData = temp;
    } else {
      this.itemsData = this.itemDataRes;
    }
  }

}
