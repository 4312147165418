<div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12 col-xl-12">
            <form [formGroup]="formUser" (ngSubmit)="validForm($event)">
                <div class="bg-light rounded h-100 p-4">
                    <h6 class="mb-4">Usuario</h6>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="mx_user" required="required" [(ngModel)]="user.mxuser">
                        <label for="name">mx_user</label>
                        <div *ngIf="formUser.controls.mx_user.errors && formUser.controls.mx_user.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="nombre" required="required" [(ngModel)]="user.nombre">
                        <label for="name">nombre</label>
                        <div *ngIf="formUser.controls.nombre.errors && formUser.controls.nombre.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="apellidos" required="required" [(ngModel)]="user.apellidos">
                        <label for="name">apellidos</label>
                        <div *ngIf="formUser.controls.apellidos.errors && formUser.controls.apellidos.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="correo" required="required" [(ngModel)]="user.correo">
                        <label for="name">correo</label>
                        <div *ngIf="formUser.controls.correo.errors && formUser.controls.correo.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="departamento" required="required" [(ngModel)]="user.departamento">
                        <label for="name">departamento</label>
                        <div *ngIf="formUser.controls.departamento.errors && formUser.controls.departamento.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="perfil" required="required" [(ngModel)]="user.perfil">
                        <label for="name">perfil</label>
                        <div *ngIf="formUser.controls.perfil.errors && formUser.controls.perfil.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="password" class="form-control" formControlName="password" required="required" [(ngModel)]="user.password">
                        <label for="name">password</label>
                        <div *ngIf="formUser.controls.password.errors && formUser.controls.password.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="id_profile" required="required" [(ngModel)]="user.id_profile">
                        <label for="name">id_profile</label>
                        <div *ngIf="formUser.controls.id_profile.errors && formUser.controls.id_profile.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>
                    <!--<div class="form-floating mb-3">
                        <textarea class="form-control" formControlName="description" [(ngModel)]="Perfil.description"></textarea>
                        <label for="descripcion">Descripcion</label>
                        <div *ngIf="formProfile.controls.description.errors && formProfile.controls.description.touched">
                            <small style="color: red;">Campo Obligatorio</small>
                        </div>
                    </div>-->
                    <div class="form-floating">
                        <div class="text-sm-end">
                            <button type="submit" class="btn btn-success">Guardar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>


    </div>
</div>

<!--div class="container-fluid pt-4 px-4">
    <div class="col-sm-12 col-xl-6">
        <div class="bg-light rounded h-100 p-4">
            <h6 class="mb-4">Lista de Modulos</h6>
            <ul class="list-unstyled mb-0">
                <li *ngFor="let menu of main">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" (change)="addProfile($event)" value="{{menu.id}}" [checked]="menu.asig != null ? true : false">
                        <label class="form-check-label" >{{menu.name}}</label>
                    </div>
                    <ul *ngIf="menu.hijos.length > 0">
                        <li *ngFor="let nivel1 of menu.hijos">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" (change)="addProfile($event)" value="{{nivel1.id}}" [checked]="nivel1.asig != null ? true : false">
                                <label class="form-check-label">{{nivel1.name}}</label>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div-->