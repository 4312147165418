import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SideBarService } from 'src/app/services/side-bar.service';

interface Filtros {
  name: string,
}

@Component({
  selector: 'app-pr-detail',
  templateUrl: './pr-detail.component.html',
  styleUrls: ['./pr-detail.component.css']
})
export class PrDetailComponent implements OnInit {

  Filtros: Filtros = this.emptyFilters();
  PR: any = {
    details: [],
    dates: []
  };
  Logs: any[] = [];

  constructor(
    private API: ApiService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sidebar: SideBarService,
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    this.set();
  }

  set() {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    if(id) {
      this.get(id);
    }
  }

  get(id: Number) {
    this.API.pr_get(`v1/subscription/${id}`, this.Filtros).subscribe((data: any) => {
      console.log(data)
      if(data.success) {
        this.PR = data.data;
      }
    }, (e: any) => {console.log(e)});
    /*this.API.pr_get(`v1/subscription/${id}`, this.Filtros).subscribe((data: any) => {
      console.log(data)
      if(data.success) {
        this.PR = data.data;
      }
    }, (e: any) => {console.log(e)});*/
  }

  emptyFilters(): Filtros {
    return {
      name: ''
    };
  }

  logs(content: any, id?: number) {
    console.log(id)
    let Filtroslogs = {order_subscription: id};
    this.API.pr_get(`v1/logs`, Filtroslogs).subscribe((data: any) => {
      console.log(data)
      if(data.success && data.data.data.length > 0) {
        this.Logs = data.data.data;

        this.modalService.open(content, { 
          windowClass: 'dark-modal',
          scrollable: true,
        });
      } else {
        alert('sin datos');
      }
    });
    /*this.modalService.open(content, { 
      windowClass: 'dark-modal',
      scrollable: true
    });*/
  }

}
