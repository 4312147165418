<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
      <div class="row">
        <h6 class="mb-4">{{ view.title }}</h6>
      </div>
      <form [formGroup]="formUpDiscount" (ngSubmit)="validForm($event)">
  
      <div class="row">
        <div class="col-sm-4">
          <div class="form-floating mb-3">
            <input
              class="btn btn-primary"
              type="file"
              (change)="onFileChange($event)"
              multiple="false"
            />
          </div>
        </div>
  
        <div class="col-sm-4">
          <div class="form-floating mb-3">
            <input *ngIf="mostrarBoton"
              type="date"
              name="startDate"
              placeholder="Fecha Inicial"
              class="form-control"
              required
              pattern="\d{4}-\d{2}-\d{2}"
              [(ngModel)]="DiscountData.startDate"
              formControlName="startDate"
            />
            <label *ngIf="mostrarBoton" for="startDate">Fecha Inicial</label>
          </div>
        </div>
  
        <div class="col-sm-4">
          <div class="form-floating mb-3">
            <input *ngIf="mostrarBoton"
              type="date"
              name="endDate"
              placeholder="Fecha Final"
              class="form-control"
              required
              pattern="\d{4}-\d{2}-\d{2}"
              [(ngModel)]="DiscountData.endDate"
              formControlName="endDate"
            />
            <label *ngIf="mostrarBoton" for="endDate">Fecha Final</label>
          </div>
        </div>
      </div>
      
      
      <div class="form-floating">
        <div class="text-sm-end">
          <button *ngIf="mostrarBoton"  type="submit" class="btn btn-dark">
            Cargar Información
          </button>
        </div>
      </div>
  
    </form>
    </div>
  </div>
  
  <div class="container-fluid pt-4 px-4">
    <div class="row g-4">
      <div class="col-sm-12">
        <div class="bg-light rounded h-100 p-4 table-responsive">
         
          <!--<div class="col-sm-4">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Buscar"
                (keyup)="updateFilter($event)"
              />
              <label for="Buscar">Buscar</label>
            </div>
          </div>-->
  
          <ngx-datatable 
            [rows]="dat"
            [columns]="columns"
            [footerHeight]="50"
            rowHeight="auto"
            [limit]="10"
            class="bootstrap"
            [columnMode]="'force'"
            [summaryRow]="true"
            [summaryPosition]="'auto'"
            [limit]="10"
            [reorderable]="reorderable"
          >
            <ngx-datatable-column prop="0" name="SKU"
              ><ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <div [ngClass]="getCellClass(row, { prop: '0' })">
                  {{ value }}
                </div>
                <!--<span *ngIf="isEmptyCol(row, { prop: '0' })">Error: la columna está vacía</span>-->
              </ng-template></ngx-datatable-column
            >
            <ngx-datatable-column prop="1" name="REBAJADO"
              ><ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <div [ngClass]="getCellClass(row, { prop: '1' })">
                  {{ value }}
                </div>
                <!--<span *ngIf="isEmptyCol(row, { prop: '0' })">Error: la columna está vacía</span>-->
              </ng-template></ngx-datatable-column
            >
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
  