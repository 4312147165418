import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { OrmService } from 'src/app/services/orm.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { HelperService } from 'src/app/services/helper.service';

interface password {
  newpassword: string,
  repitpassword: string
}

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})
export class MyprofileComponent implements OnInit {

  constructor(
    private API: ApiService,
    private ORM: OrmService,
    private formBuilder: FormBuilder,
    private sidebar: SideBarService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
  }

  password: password = {
    newpassword: '',
    repitpassword: ''
  }

  formPassword = this.formBuilder.group({
    newpassword: ['', [Validators.required, Validators.minLength(4)]],
    repitpassword: ['', [Validators.required, Validators.minLength(4)]],
  });

  validForm(event: Event) {
    event.preventDefault();
    if(this.formPassword.valid) {
      this.agregar();
    } else {
      this.formPassword.markAllAsTouched();
    }
  }

  agregar(): void {
    if(this.password.newpassword !== this.password.repitpassword) {
      this.helperService.show('No coincide el password', { classname: `bg-danger text-light`, delay: 1500 });
      return;
    }

    this.API._put(`/sistema/usuario/${this.ORM.getCurrentUser().id_user}`, {password: this.password.newpassword}).subscribe((data: any) => {
      if(data.result == 'ok') {
        this.helperService.show('Datos Actualizados', { classname: `bg-success text-light`, delay: 1500 });
        this.password.newpassword = '';
        this.password.repitpassword = '';
      } else {
        if(data.e.name === 'TokenExpiredError') {
          this.helperService.logOut();
        } else {
          this.helperService.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    });
  }

}
