import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SideBarService } from 'src/app/services/side-bar.service';

interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface Filtros {
  name: string,
  email: string,
  status_subscription: string,
  page: number,
  page_size: number | null
}

@Component({
  selector: 'app-recurring-orders',
  templateUrl: './recurring-orders.component.html',
  styleUrls: ['./recurring-orders.component.css']
})
export class RecurringOrdersComponent implements OnInit {

  view: view = {
    title: 'Pedidos Recurrentes',
    isLoading: true,
    _url: 'v1/subscription'
  }
  Filtros: Filtros = this.emptyFilters();
  resultados: any[] = [];

  STATUS: string[] = ["Activo", "Pausado", "NoProgramado", "Cancelado"];
  total: number = 0;

  constructor(
    private API: ApiService,
    private sidebar: SideBarService,
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    this.listar();
  }

  listar(): void {
    this.view.isLoading = true;
    this.API.pr_get(this.view._url, this.Filtros).subscribe((res: any) => {
      this.view.isLoading = false;
      console.log(res)
      if(res.success) {
        this.resultados = res.data.data;
        this.total = res.data.total * 12;
      }
    });
  }

  clear(): void {
    this.Filtros = this.emptyFilters();
    this.listar();
  }

  emptyFilters(): Filtros {
    return {
      name: '',
      email: '',
      status_subscription: '',
      page: 1,
      page_size: null
    };
  }

  pagination(event: any) {
    this.Filtros.page = event;
    this.listar();
  }

}
