import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OrmService } from './orm.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  PROTOCOL: string = 'https://';
  ENDPOINT: string = this.PROTOCOL+'hebaz-as-ecomtoolsback-cert.eastus.cloudapp.azure.com/v1';




  //ENDPOINTPR: string = this.PROTOCOL+'hebaz-as-multicanal-prod-eastus-001.azurewebsites.net/';
  ENDPOINTPR: string = this.PROTOCOL+'hebaz-as-ecomtoolsback-dev.eastus.cloudapp.azure.com/';

  TOKENPR: string = '9785c3d4e190c115f5e8fd0600003599';

  constructor(
    private http: HttpClient,
    private ORM: OrmService
    ) {}

  postHomeBanner(formData: FormData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
    return this.http.post(`${this.ENDPOINT}/uploadHomeBanner`, formData, httpOptions);
  }

  postSplashDynamic(formData: FormData, color: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
    return this.http.post(`${this.ENDPOINT}/uploadSplashDynamic?color=${color}`, formData, httpOptions);
  }

  getUser(name: string, password: string) {
    return this.http.get(`${this.ENDPOINT}/users/${name}/${password}`);
  }

  postUser(data: object) {
    return this.http.post(`${this.ENDPOINT}/users`, data);
  }

  //Rebajados
  getCorewebsite() {
    return this.http.get(`${this.ENDPOINT}/magento/corewebsite`);
  }

  postLogin(user: object) {
    return this.http.post(`${this.ENDPOINT}/users/login`, user);
  }

  //Stores
  getStores(data: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'applications/json',
        'authorization': `Bearer ${this.ORM.getCurrentToken()}`
      })
    }
    return this.http.get(`${this.ENDPOINT}/stores`, httpOptions);
  }

  putOrderStore(data: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': `Bearer ${this.ORM.getCurrentToken()}`
      })
    }
    return this.http.put(`${this.ENDPOINT}/orders/store`, data, httpOptions);
  }

  //AWS
  sshStaging(body: any) {
    const url_aws = 'https://qe06ndtv7j.execute-api.us-east-2.amazonaws.com/Test/sshstaging';
    const api_key = 'jVcouWSW8Q6CVY3PcgYRF5qj518j1LEya0acSJVQ';

    const httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': `${api_key}`,
        'Content-Type': 'text/plain'
      })
    }

    var data = '{\n"username": "'+body.username+'",\n"ip": "'+body.ip+'"\n}';
    console.log(`${url_aws}`, data, httpOptions)
    return this.http.post(`${url_aws}`, data, httpOptions);
  }

  ipServiceBack(api_key: string, body: object, query: object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-API-KEY': `${api_key}`,
      })
    }
    return this.http.put(`${this.ENDPOINT}/users/updateIp${this.getQueryString(query)}`, body, httpOptions);
  }

  getMyIp() {
    return this.http.get(`https://api.ipify.org?format=json`);
  }

  getQueryString(query: any): string {
    var cad = '?';
    for (const row in query) {
      if(query[row] == null || query[row] == '') {
        continue;
      }
      cad = cad+`${row}=${query[row]}&`;
    }
    return cad.slice(0, -1);;
  }

  /**
   * Generic Methods
   */

  _post(_url: string, data: object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': `Bearer ${this.ORM.getCurrentToken()}`
      })
    }
    return this.http.post(`${this.ENDPOINT}/${_url}`, data, httpOptions);
  }

  _get(_url: string, filtros: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'applications/json',
        'authorization': `Bearer ${this.ORM.getCurrentToken()}`
      })
    }
    return this.http.get(`${this.ENDPOINT}/${_url}/${this.getQueryString(filtros)}`, httpOptions);
  }

  _put(_url: string, data: object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': `Bearer ${this.ORM.getCurrentToken()}`
      })
    }
    return this.http.put(`${this.ENDPOINT}/${_url}`, data, httpOptions);
  }

  /**
   * Generic Methods PR
   */

  pr_post() {
    
  }

  pr_get(_url: string, filtros: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'applications/json',
        'authorization': `Bearer ${this.TOKENPR}`
      })
    }
    return this.http.get(`${this.ENDPOINTPR}/${_url}/${this.getQueryString(filtros)}`, httpOptions);
  }

  pr_put() {}

  pushinyeccion (dataOrder: any) {
    return this.http.post(`${this.ENDPOINT}/pushinyeccion/data`, dataOrder);
  }

  createPushInyeccion (dataInyection: any) {
    return this.http.post(`${this.ENDPOINT}/createpushinyeccion/data`, dataInyection);
  }

  getRushOrder (dataOrder: any) {
    return this.http.post(`${this.ENDPOINT}/rushOrder/data`, dataOrder);
  }

  rushOrderProcess (dataOrder: any) {
    return this.http.post(`${this.ENDPOINT}/rushOrder/process`, dataOrder);
  }

  eventOrderMovents (dataOrder: any) {
    return this.http.post(`${this.ENDPOINT}/orderMovents/data`, dataOrder);
  }
}
