<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
        <div class="row">
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <p>In Developing</p>
                </div>
            </div>
        </div>
    </div>
</div>