<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
        <div class="row">
            <h6 class="mb-4">{{view.title}}</h6>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="mail" [(ngModel)]="Filtros.mail">
                    <label for="name">Correo</label>
                </div>
            </div>
        </div>
        <button class="btn btn-primary" (click)="listar()" [disabled]="view.isLoading">Buscar</button>
        <button class="btn btn-warning" (click)="clear()" [disabled]="view.isLoading">Limpiar</button>
    </div>
</div>
<div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12 col-xl-12">
            <div class="bg-light rounded h-100 p-4 table-responsive">
                <ng-template [ngIf]="view.isLoading" [ngIfElse]="apiResponse">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status" >
                          <span class="sr-only" id="loading"></span>
                        </div>
                      </div>
                </ng-template>
                <ng-template #apiResponse>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Sku</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Depto</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Precio</th>
                                <th scope="col">Subtotal</th>
                                <th scope="col">Total</th>
                                <th scope="col">MaxVentaxSku</th>
                                <th scope="col">Cupon</th>
                                <th scope="col">Tienda</th>
                                <th scope="col">InventarioTienda</th>
                                <th scope="col">FechaEntrega</th>
                                <th scope="col">Mensaje</th>
                                <th scope="col">StatusCarrito</th>
                                <th scope="col">Email</th>
                                <th scope="col">Fuente</th>
                                <th scope="col">Prime</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template [ngIf]="resultados.length > 0" [ngIfElse]="notData">
                                <tr *ngFor="let Result of resultados">
                                    <td>{{Result.Sku}}</td>
                                    <td>{{Result.Nombre}}</td>
                                    <td>{{Result.Depto}}</td>
                                    <td>{{Result.Cantidad}}</td>
                                    <td>{{Result.Precio | currency}}</td>
                                    <td>{{Result.Subtotal | currency}}</td>
                                    <td>{{Result.Total | currency}}</td>
                                    <td>{{Result.MaximoVentaPorSku}}</td>
                                    <td>{{Result.Cupon}}</td>
                                    <td>{{Result.Tienda}}</td>
                                    <td>{{Result.InventarioTienda}}</td>
                                    <td>{{Result.FechaEntrega | date: 'd/M/yy'}}</td>
                                    <td>{{Result.Mensaje}}</td>
                                    <td>{{Result.StatusCarrito}}</td>
                                    <td>{{Result.Email}}</td>
                                    <td>{{Result.Fuente}}</td>
                                    <td>{{Result.EsPrime}}</td>
                                </tr>
                            </ng-template>
                            <ng-template #notData>
                                <tr>
                                    <td colspan="17" scope="row" style="text-align: center;">Sin Resultados!!</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                    <!--ngb-pagination [collectionSize]="total" [pageSize]="12" [maxSize]="6" [rotate]="true" [ellipses]="false" [(page)]="Filtros.page" (pageChange)="pagination($event)"></ngb-pagination-->
                </ng-template>
            </div>
        </div>
    </div>
</div>