<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
        <div class="row">
            <h6 class="mb-4">{{view.title}}</h6>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="name" [(ngModel)]="Filtros.name">
                    <label for="name">Nombre</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="email" [(ngModel)]="Filtros.email">
                    <label for="email">Correo</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <select class="form-control" id="status_subscription" [(ngModel)]="Filtros.status_subscription">
                        <option value="">Seleccione</option>
                        <option *ngFor="let status of STATUS" value="{{status}}">{{status}}</option>
                    </select>
                    <label for="status_subscription">Estatus</label>
                </div>
            </div>
        </div>
        <button class="btn btn-primary" (click)="listar()" [disabled]="view.isLoading">Buscar</button>
        <button class="btn btn-warning" (click)="clear()" [disabled]="view.isLoading">Limpiar</button>
    </div>
</div>
<div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12">
            <div class="bg-light rounded h-100 p-4 table-responsive">
                <ng-template [ngIf]="view.isLoading" [ngIfElse]="apiResponse">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status" >
                          <span class="sr-only" id="loading"></span>
                        </div>
                      </div>
                </ng-template>
                <ng-template #apiResponse>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Email</th>
                                <th scope="col">Estatus</th>
                                <th scope="col">Inicio</th>
                                <th scope="col">Fin</th>
                                <th scope="col">Items</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template [ngIf]="resultados.length > 0" [ngIfElse]="notData">
                                <tr *ngFor="let Result of resultados">
                                    <td><a href="/pr-detail/{{Result.subscription_id}}">{{Result.subscription_id}}</a></td>
                                    <td>{{Result.name}}</td>
                                    <td>{{Result.email}}</td>
                                    <td>{{Result.status_subscription}}</td>
                                    <td>{{Result.start_date | date: 'd/M/yy'}}</td>
                                    <td>{{Result.expires | date: 'd/M/yy'}}</td>
                                    <td>{{Result.totalItems}}</td>
                                </tr>
                            </ng-template>
                            <ng-template #notData>
                                <tr>
                                    <td colspan="4" scope="row" style="text-align: center;">Sin Resultados!!</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                    <ngb-pagination [collectionSize]="total" [pageSize]="12" [maxSize]="6" [rotate]="true" [ellipses]="false" [(page)]="Filtros.page" (pageChange)="pagination($event)"></ngb-pagination>
                </ng-template>
            </div>
        </div>
    </div>
</div>