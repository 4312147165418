import { Component, OnInit, Input } from '@angular/core';
import { OrmService } from 'src/app/services/orm.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  @Input() user: string = '';
  isOpen: string = '';

  constructor(
    private ORM: OrmService,
    private Helper: HelperService,
    private sideBarService: SideBarService
  ) { }

  ngOnInit(): void {
  }

  open() {
    this.sideBarService.toggle();
    this.isOpen = this.sideBarService.isOpen;
  }

  salir() {
    this.Helper.logOut();
  }

}
