import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { OrmService } from 'src/app/services/orm.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { HelperService } from 'src/app/services/helper.service';

interface Store {
  website_id?: null | Number,
  code: string,
  default_store_id: null | Number,
  name: string
}

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  Stores: Store[] = [];
  update = {
    increment_id: '',
    store_id: ''
  }
  cp: string = '';
  Store: any;

  constructor(
    private API: ApiService,
    private ORM: OrmService,
    private router: Router,
    private sidebar: SideBarService,
    private formBuilder: FormBuilder,
    private Helper: HelperService,
  ) { }

  form = this.formBuilder.group({
    increment_id: ['', Validators.required],
    store_id: ['', Validators.required],
  });

  validForm(event: Event) {
    event.preventDefault();
    if(this.form.valid) {
      this.save();
    } else {
      this.form.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.getStores();
  }

  getStores() {
    this.API.getStores({}).subscribe((data: any) => {
      console.log(data)
      if(data.result === 'ok') {
        this.Stores = data.data;
      } else {
        if(data.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
      this.sidebar.showLoading();
    }, (e: any) => {
      console.warn('getStores -> ', e);
    });
  }

  logout(): void  {
    this.ORM.logout();
    this.router.navigate(['/login']);
  }

  save(): void {
    console.log(this.update);
    this.API.putOrderStore(this.update).subscribe((data: any) => {
      console.log(data)
      if(data.result === 'ok') {
        if(data.data !== 'NotData') {
          this.Helper.show('Datos Actualizados', { classname: `bg-success text-light`, delay: 1500 });
        } else {
          this.Helper.show(data.e, { classname: `bg-danger text-light`, delay: 1500 });
        }
      } else {
        if(data.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    }, (e: any) => {
      console.warn('putOrderStore -> ', e);
    });
  }

}
