<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
        <div class="row">
            <h6 class="mb-4">{{view.title}}</h6>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="name" [(ngModel)]="Filtros.name">
                    <label for="name">Nombre</label>
                </div>
            </div>
        </div>
        <button class="btn btn-primary" (click)="listar()" [disabled]="view.isLoading">Buscar</button>
        <button class="btn btn-warning" (click)="clear()" [disabled]="view.isLoading">Limpiar</button>
    </div>
</div>
<div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12">
            <div class="bg-light rounded h-100 p-4 table-responsive">
                <a routerLink="/profile" class="btn btn-success">Agregar Perfil</a>
                <ng-template [ngIf]="view.isLoading" [ngIfElse]="apiResponse">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status" >
                          <span class="sr-only" id="loading"></span>
                        </div>
                      </div>
                </ng-template>
                <ng-template #apiResponse>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Descripcion</th>
                                <th scope="col">Estatus</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template [ngIf]="resultados.length > 0" [ngIfElse]="notData">
                                <tr *ngFor="let Result of resultados">
                                    <td><a href="/profile/{{Result.id}}">{{Result.id}}</a></td>
                                    <td>{{Result.name}}</td>
                                    <td>{{Result.description}}</td>
                                    <td>{{Result.status}}</td>
                                </tr>
                            </ng-template>
                            <ng-template #notData>
                                <tr>
                                    <td colspan="4" scope="row" style="text-align: center;">Sin Resultados!!</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                    <ngb-pagination [collectionSize]="total" [pageSize]="12" [maxSize]="6" [rotate]="true" [ellipses]="false" [(page)]="Filtros.page" (pageChange)="pagination($event)"></ngb-pagination>
                </ng-template>
            </div>
        </div>
    </div>
</div>